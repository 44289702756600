body {
  color: #333;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  margin: 0;
  padding: 50px;
}

.command-bar {
  background: white;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  display: grid;
  gap: 12px;
  grid-template-columns: 4fr 1fr;
  padding: 24px 50px;;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}

input[type="text"] {
  border-radius: 6px;
  border: 1px solid #ccc;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  padding: 12px 18px;
}

input[type="text"]:focus-visible {
  border: 1px solid #15BFA3;
  outline: 1px solid #15BFA3;
}

button[type="submit"] {
  background-color: #15BFA3;
  border: none;
  border-radius: 6px;
  color: white;
  cursor: pointer;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  padding: 12px 18px;
  transition: background-color 0.2s linear;
}

button[type="submit"]:hover {
  background-color: #0f937d;
}

.analysis-wrapper {
  margin-top: 72px;
}

.analysis-grid {
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;
}

.analysis-card {
  background: #F2EEFB;
  border-radius: 12px;
  padding: 24px 30px;
}

.summary {
  background: #E8FCF9;
  border-radius: 12px;
  grid-column: span 3;
  padding: 24px 30px;
}

.analysis-card:nth-child(1) {
  background: #E8FCF9;
}

.loader-wrapper {
  background: white;
  border-radius: 12px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  left: 50%;
  padding: 24px;
  position: fixed;
  top: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.small-caps, .badge {
  font-size: 0.8rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.badge {
  background: #B8ECE3;
  border-radius: 100px;
  color: #0F7C6A;
  display: inline-block;
  font-weight: 700;
  padding: 5px 15px;
  text-align: center;
}

.badge.medium {
  background: #FCF0C5;
  color: #F6543E;
}

.badge.low {
  background: #FDCDCD;
  color: #A80D0E;
}

.badge.fixed-width {
  min-width: 63px;
}

.screenings-wrapper {
  margin-top: 72px;
}

.table {
  background: white;
  border-radius: 12px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  margin-top: 18px;
}

.row {
  align-items: center;
  border-top: 1px solid #eee;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 15px 18px;
}

.first-row {
  border-top: none;
}

.suggestions {
  background: white;
  border-radius: 12px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  left: 30px;
  overflow: hidden;
  position: fixed;
  right: 30px;
  top: 90px;
}

.suggestion {
  justify-content: center;
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  padding: 18px 24px;
  text-align: center;
  text-wrap: pretty;
  min-height: 100px;
  cursor: pointer;
}

.suggestion:hover {
  background: #E8FCF9;
}